exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-archeoplanetography-apg-101-jsx": () => import("./../../../src/pages/archeoplanetography/apg-101.jsx" /* webpackChunkName: "component---src-pages-archeoplanetography-apg-101-jsx" */),
  "component---src-pages-articles-jsx": () => import("./../../../src/pages/articles.jsx" /* webpackChunkName: "component---src-pages-articles-jsx" */),
  "component---src-pages-board-of-directors-and-advisors-jsx": () => import("./../../../src/pages/board-of-directors-and-advisors.jsx" /* webpackChunkName: "component---src-pages-board-of-directors-and-advisors-jsx" */),
  "component---src-pages-coloring-books-kids-art-jsx": () => import("./../../../src/pages/coloring-books/kids-art.jsx" /* webpackChunkName: "component---src-pages-coloring-books-kids-art-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-donation-cancelled-jsx": () => import("./../../../src/pages/donation-cancelled.jsx" /* webpackChunkName: "component---src-pages-donation-cancelled-jsx" */),
  "component---src-pages-free-downloads-jsx": () => import("./../../../src/pages/free-downloads.jsx" /* webpackChunkName: "component---src-pages-free-downloads-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-resource-links-jsx": () => import("./../../../src/pages/resource-links.jsx" /* webpackChunkName: "component---src-pages-resource-links-jsx" */),
  "component---src-pages-sasquatch-the-sasquatch-message-to-humanity-jsx": () => import("./../../../src/pages/sasquatch/the-sasquatch-message-to-humanity.jsx" /* webpackChunkName: "component---src-pages-sasquatch-the-sasquatch-message-to-humanity-jsx" */),
  "component---src-pages-store-jsx": () => import("./../../../src/pages/store.jsx" /* webpackChunkName: "component---src-pages-store-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-thank-you-jsx": () => import("./../../../src/pages/thank-you.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */),
  "component---src-pages-ummo-oummo-craft-details-jsx": () => import("./../../../src/pages/ummo-oummo/craft-details.jsx" /* webpackChunkName: "component---src-pages-ummo-oummo-craft-details-jsx" */),
  "component---src-pages-ummo-oummo-message-jsx": () => import("./../../../src/pages/ummo-oummo/message.jsx" /* webpackChunkName: "component---src-pages-ummo-oummo-message-jsx" */),
  "component---src-pages-ummo-oummo-symbols-jsx": () => import("./../../../src/pages/ummo-oummo/symbols.jsx" /* webpackChunkName: "component---src-pages-ummo-oummo-symbols-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-resource-jsx": () => import("./../../../src/templates/resource.jsx" /* webpackChunkName: "component---src-templates-resource-jsx" */)
}

